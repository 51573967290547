import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import $ from "jquery";
import {NavLink, Link} from 'react-router-dom';

import HomeTab2 from './HomeTab2'

class HomeWeb extends Component {

	state = {
		active: [],
		active2: [],
		active3: Math.random() < 0.5,
	};

	componentDidMount() {
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);
		window.addEventListener("scroll", this._onScroll);
	}

	lastScrollTop = 0;

	_onScroll = () => {
		let helper_offset = null;
		let helper2_offset = null;

		const st = window.pageYOffset || document.documentElement.scrollTop;
		const downscroll = st > this.lastScrollTop;

    if(this.helper){
      helper_offset = $(this.helper).offset().top;
    }

    if(this.helper2){
		  helper2_offset = $(this.helper2).offset().top;
    }


		this.lastScrollTop = st <= 0 ? 0 : st;
		const self = this;

		$(".scroll-line").each(function (e) {
			if(helper_offset){
				if ($(this).offset().top <= helper_offset && downscroll) {
					self.setState({
						active2: self.state.active2.filter((num) => num !== e),
						active: [...self.state.active, e],
					});
				}
			}
			if(helper2_offset){
				if ($(this).offset().top > helper2_offset && !downscroll) {
					self.setState({
						active: self.state.active.filter((num) => num !== e),
						active2: [...self.state.active2, e],
					});
				}
			}
		});
	};

	render() {
		return (
      <Fragment>

				<header className="header-projects-new">
          <menu className="main">
            <NavLink to="/projects/all/" onClick={() => this.props.changeCase(null)}>Создаём</NavLink>
            <NavLink to="/accents/">Акценты</NavLink>
            <NavLink to="/about/">О нас</NavLink>
            <NavLink to="/contacts/">Контакты</NavLink>
            <NavLink to="/advertising/">Продвигаем</NavLink>
          </menu>

          <div className="sources-tags-overlay">
            <nav className="tags sources-tags">
              <NavLink to="/accents/banks/"><span data-index="banks">Банки</span></NavLink>
              <NavLink to="/accents/marketing/"><span data-index="marketing">Комплексный маркетинг в недвижимости</span></NavLink>
              <NavLink to="/accents/404/"><span data-index="404">404</span></NavLink>
              <NavLink to="/accents/ccp/"><span data-index="ccp">CCP</span></NavLink>
              <NavLink to="/accents/reality/"><span data-index="reality">Дополненная реальность</span></NavLink>
              <NavLink to="/accents/audit/"><span data-index="audit">Аудит контекстной рекламы</span></NavLink>
            </nav>
          </div>

          <nav className="tags projects-tags">
            <NavLink to="/projects/all/"><span>Всё</span></NavLink>
            <NavLink to="/projects/web/"><span>Сайты</span></NavLink>
            <NavLink to="/projects/branding/"><span>Брендинг</span></NavLink>
            <NavLink to="/projects/apps/"><span>Приложения</span></NavLink>
          </nav>
      
          <Link to="/" className="logo"><i></i></Link>
        </header>

        <div className="promote__top-bottom-button" onClick={() => this.props.openPopup(true)}>
					<span>Мы на связи</span>
					{/* <img src="/img/promote/lightning_promote.svg" alt=""/> */}
				</div>

        <HomeTab2 {...this.state}>
          <Fragment>
            <div className="sroll-helper" ref={(r) => (this.helper = r)} />
            <div className="sroll-helper2" ref={(r) => (this.helper2 = r)} />
          </Fragment>
        </HomeTab2>

      </Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWeb);
