import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooterNew from '../CaseFooterNew';
import HelmetSite from '../HelmetSite';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.min.css";

class Domgorizontov extends Component{
  swiperRef = React.createRef();
  state = {
		preview: true,
		play: false,
		mobile: window.innerWidth <= 1000
	};

	resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    var video = document.getElementById("video");
    $(".videoBlock__btn").click(function () {
      $(".videoBlock__btn").toggleClass("videoBlock__btn_hidden");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
		if(this.props.pathname == '/projects/domgorizontov/'){
			this.setState({preview: false});
			this.props.setProjectName('Domgorizontov');
			$('body').addClass('case-expanded hash-domgorizontov');
			$(window).scrollTop($('#case-domgorizontov').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-domgorizontov');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-domgorizontov');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-domgorizontov">

        <div className="screen">
          <div className="topScreen">
            <div className="topScreen__bg" style={{backgroundImage: `url('/cases/img/domgorizontov/bg-top.webp')`}}>
              {!this.state.mobile && (<video playsInline autoPlay poster="/cases/img/domgorizontov/video_bg.webp" muted loop>
                <source src="/cases/img/domgorizontov/video_bg.mp4" type="video/mp4" />
              </video>)}
            </div>
            <div className="topScreen__title"><img src="/cases/img/domgorizontov/title.svg" alt="" /></div>
          </div>
          <div className="aboutProject">
            <div className="content">
              <div className="aboutProject__wrap">
                <div className="aboutProject__left">
                  <div className="aboutProject__title title">Дом горизонтов</div>
                  <div className="aboutProject__desc">
                    <div>
                      <div>Клиент</div>
                      <div>Touch Development</div>
                    </div>
                    <div>
                      <div>Виды&nbsp;работ</div>
                      <div>Создание сайта</div>
                    </div>            
                  </div>          
                </div>
                <div className="aboutProject__text text">
                  В концепции дизайна мы активно используем фирменный стиль и&nbsp;отражаем символику, заложенную в&nbsp;бренде: образ горизонта, дома–маяка.
                  <br/><br/>
                  Дизайн насыщен горизонтальными изображениями, линиями, а&nbsp;также анимированными заголовками, которые плавно перемещаются по&nbsp;горизонтали, всё это поддерживает заложенную метафору. Так же мы показываем крупные фрагменты архитектуры, но уже в&nbsp;вертикальной плоскости, это цитаты дома–маяка.
                  <br/><br/>  
                  С самого начала мы представляем дом величественно, он&nbsp;словно маяк, гордо возвышающийся над всем. Взгляд скользит между двумя перспективами: на дом и&nbsp;из&nbsp;дома. Глядя на&nbsp;дом, мы впечатляемся игрой архитектурных форм и&nbsp;линий. Изнутри мы наслаждаемся великолепными видами на&nbsp;окружающую природу. Мы сразу передаём взаимодействие между передовой высокотехнологичной архитектурой и&nbsp;живой природной красотой окружающего мира.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox">
            <div className="gridBox__item gridBox__item_1"><img src="/cases/img/domgorizontov/img1.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_2" style={{backgroundImage: `url('/cases/img/domgorizontov/img2.webp')`}}></div>
            <div className="gridBox__item gridBox__item_3">
              <div className="boxFlex">
                <div className="boxFlex__text"><div>Весь город под вашими ногами,<br/>и горизонт ваших возможностей<br/>безграничен</div></div>
                <img src="/cases/img/domgorizontov/img3.webp" alt="" />
              </div>
            </div>
            <div className="gridBox__item gridBox__item_4" style={{backgroundImage: `url('/cases/img/domgorizontov/img4.webp')`}}></div>
            <div className="gridBox__item gridBox__item_5" style={{backgroundImage: `url('/cases/img/domgorizontov/img5.webp')`}}>
              <div>В&nbsp;каждой квартире комплекса есть балконы, а&nbsp;в&nbsp;пентхаусах предусмотрен выход на&nbsp;террасу. Стоит сделать всего несколько шагов, и&nbsp;вы на&nbsp;открытом воздухе</div>
            </div>
            <div className="gridBox__item gridBox__item_6">
              <div className="gridChild">
                <div className="gridChild__item gridChild__item_1"><img src="/cases/img/domgorizontov/img6.webp" alt="" /></div>
                <div className="gridChild__item gridChild__item_2"><img src="/cases/img/domgorizontov/img7.webp" alt="" /></div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_7">
              <div className="videoBlock">
                <div className="videoBlock__btn" style={{ backgroundImage: `url('/cases/img/domgorizontov/play.svg')` }}></div>
                <video id="video" poster="/cases/img/domgorizontov/poster.webp" loop preload="metadata">
                  <source src="/cases/img/domgorizontov/video.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_8">
              <div className="gridChild2">
                <div className="gridChild2__item gridChild2__item_1"><img src="/cases/img/domgorizontov/img8.webp" alt="" /></div>
                <div className="gridChild2__item gridChild2__item_2" style={{backgroundImage: `url('/cases/img/domgorizontov/img9.webp')`}}></div>
                <div className="gridChild2__item gridChild2__item_3"><img src="/cases/img/domgorizontov/img10.webp" alt="" /></div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_9">
              <img src="/cases/img/domgorizontov/img11.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_10">
              <img src="/cases/img/domgorizontov/img12.webp" alt="" />
              <img src="/cases/img/domgorizontov/img13.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_11">
              <div className="gridSmall">
                <div className="gridSmall__text text">                  
                  Мобильная версия сайта жилого комплекса полностью повторяет функционал основной версии, обеспечивая пользователям доступ ко&nbsp;всем необходимым функциям в&nbsp;любое время и&nbsp;в&nbsp;любом месте. 
                  <br/><br/>
                  Интерфейс оптимизирован для удобства использования на мобильных устройствах, что&nbsp;делает навигацию простой и интуитивной. При&nbsp;этом визуальный дизайн сохраняет эстетическую привлекательность, отражая фирменный стиль проекта и&nbsp;создавая гармоничное восприятие даже на&nbsp;небольших экранах.
                </div>
                <div className="gridSmall__img"><img src="/cases/img/domgorizontov/img14.webp" alt="" /></div>                
              </div>
            </div>
            <div className="gridBox__item gridBox__item_12">
              <img src="/cases/img/domgorizontov/img15.webp" alt="" />
              <img src="/cases/img/domgorizontov/img16.webp" alt="" />
              <img src="/cases/img/domgorizontov/img17.webp" alt="" />
              <img src="/cases/img/domgorizontov/img18.webp" alt="" />
            </div>
          </div>
        </div>        
			</div>  
      
      <CaseFooterNew 
				to={ this.props.next ? this.props.next.url: "/projects/afipark/"  }
				title= { this.props.next ? this.props.next.name: `Дом горизонтов`   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/domgorizontov/domgorizontov.webp" }
			/>
      <HelmetSite title='Дом горизонтов' />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Дом горизонтов</div>
				<div className="tags"><span>сайт</span><span>Копирайтинг</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Domgorizontov)