import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {Route, Switch} from 'react-router-dom';

import $ from 'jquery';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';

// import HomeNew from './home/HomeNew';
import HomeAll from './home/HomeAll';
import HomeWeb from './home/HomeWeb';
import HomeBranding from './home/HomeBranding'
import HomeApp from './home/HomeApp'

import Eph from './eph';
import Ryadom from './ryadom';
import Ikforum from './ikforum';
import ComCity from './comcity';
import Kakumae from './kakumae';
import Sistemavc from './sistemavc';
import Efimovbor from './efimovbor';
import Imeretinka from './imeretinka';
import Fsklider from './fsklider';
import Polyanka from './polyanka';
import Tushino from './tushino';
import X5 from './x5';
import Video from './video';
import Activity from './activity';
import X5ny from './x5ny';
import Greenpolza from './greenpolza';
import Fabcab from './fabcab';
import Aresidence from './aresidence';
import Torpedo from './torpedo';
import GeneralInvest from './generalinvest';
import Botanica from './botanica';
import X5happy from './x5happy';
import Gals from './gals';
import Schastye from './schastye';
import Iskra from './iskra';
import Ingrad from './ingrad';
import X5values from './X5values';
import Westgarden from './westgarden';
import Notfound from './Notfound';
import Error from '../Error';
import Hunter from './Hunter';
import Academy from './academy';
import Atm from './atm';
import Tvz from './tvz';
import F92 from './f92';
import Bsg from './bsg';
import Afimall from './afimall';
import Self from './self';
import Vsn from './vsn';
import Kms from './kms';
import Vrb from './vrb';
import Rep from './rep';
import Armani from './armani';
import Dh from './dh';
import Mts from './mts';
import Cto from './cto';
import Ahead from './ahead';
import Сrimea from './crimea';
import Genesis from './genesis';
import Soyuz from './soyuz';
import Dellin from './dellin';
import Target from './target';
import Scandipakk from './scandipakk';
import Pp from './pp';
import {hit} from '../metrics';
import Profit from './profit'
import Metropolia from './metropolia';

import SistemavcTest from './_back-project-files/SistemavcTest';
import Emotion from './emotion'
import Detali from './detali'
import Plus from './plus'
import X5Table from './x5table'
import Voshod from './voshod'
import Dune from './dune'
import Afipark from './afipark'
import Domgorizontov from './domgorizontov'
import Neshalashi from './neshalashi'

class ProjectsNew extends Component{

	componentDidMount(){
		this.props.setPageId('projects');

		$('body').addClass('page-projects tags-expanded page-projects-new');

		$(window).unbind('scroll').bind('scroll', function(){

			let scr = $(document).scrollTop();
			let ww = $(window).width();
			let wh = $(window).height();
			let ww2 = ww + ww;
			let ww4 = ww2 + ww2;
			let wh05 = wh * 0.5;
			let wmin = scr + wh * 0.3;
			let wm = scr + wh * 0.5;
			let wmax = scr + wh * 0.7;
			let cs =  $('section.intro.projects .case:visible');

			if(scr > 0){
				$('body').addClass('fixed');
			}
			else{
				$('body').removeClass('fixed');
			}

			cs.each(function() {
				let c = $(this);
				let ct = c.offset().top;
				let ch = c.outerHeight();
				let cb = ct + ch;
				let sc = c.find('.screen' + (c.hasClass('preview') ? '0' : ''));

				if(sc.find('.parallax-back:visible').length){
					sc.find('.parallax-back:visible').each(function() {
						let b = $(this);
						let bh = b.outerHeight();
						let bt = b.offset().top;
						let bb = bt + bh;
						b.toggleClass('fixed', scr >= bt);
						let v = b.find('video');
						if (v.length && v.find('source').attr('src')) {
							v.toggleClass('invisible', scr + wh < bt || scr > bb);
							if (scr + wh >= bt && scr <= bb) {
								document.getElementById(v.attr('id')).play();
							}
							else {
								document.getElementById(v.attr('id')).pause();
							}
						}
					});
				}

				if (scr + wh05 >= ct && scr + wh05 <= cb) {
					if (!c.hasClass('preview')) {
						if(c.find('.parallax-front:visible').length ){
							c.find('.parallax-front:visible').each(function() {
								let s = $(this);
								let isBubble = s.hasClass('bubble');
								if (isBubble) {
									wmin = wmax;
								}
								let isLoadedImage = s.attr('data-text')
								if (isLoadedImage) {
									return;
								}
								let sh = s.outerHeight();
								let st = s.offset().top;
								let sm = st + sh * 0.3;
								s.toggleClass('parallax-up', sm < wmin).toggleClass('parallax-down', sm > wmax);
							});
						}

						if(c.find('.parallax-side:visible').length){
							c.find('.parallax-side:visible').each(function() {
								let s = $(this);
								let sd = s.find('div');
								let sw = sd.outerWidth();
								let sh = s.outerHeight();
								let st = s.offset().top;
								let sm = st + sh * 0.5;
								if (sw > ww) {
									let x = (sm - wm) * 100 / ww;
									x = Math.max(Math.min(x, 25), -25);
									sd.css({'-webkit-transform': 'translateX(' + x + '%)', 'transform': 'translateX(' + x + '%)'});
								}
							});
						}

						if(c.find('.parallax-slider:visible').length){
							c.find('.parallax-slider:visible').each(function() {
								let s = $(this);
								let sd = s.find('.line');
								let sw = sd.outerWidth();
								let sh = s.outerHeight();
								let st = s.offset().top;
								let sm = st + sh * 0.5;
								if (sw < ww4) {
									let d = ww4 - sw;
									let si = sd.find('.item');
									let l = si.length;
									let iw = sw / l;
									let n = Math.ceil(d / iw);
									for (let i = 0; i < n; i ++) {
										let a = si.eq(i % l);
										if (typeof(a) != 'undefined') {
                      if($(this).hasClass('invert')){
                        a.clone().prependTo(sd);
                      }
                      else{
                        a.clone().appendTo(sd);
                      }
										}
									}
									sw = sd.outerWidth();
									sd.css({'margin-left': (- sw * 0.5) + 'px'});
								}
								let x = (sm - wm) * 100 / ww;
                x = Math.max(Math.min(x, 25), -25);
                
                if($(this).hasClass('invert')){
                  sd.css({'-webkit-transform': 'translateX(-' + x + '%)', 'transform': 'translateX(-' + x + '%)'});
                }
                else{
                  sd.css({'-webkit-transform': 'translateX(' + x + '%)', 'transform': 'translateX(' + x + '%)'});
                }
							});
            }


					}
				}
			});
		});

		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
    hit(window.location.pathname);
	}

  componentDidUpdate(){
    $('body').addClass('page-projects tags-expanded page-projects-new');
  }

	componentWillUnmount(){
		$('body').removeClass('page-projects tags-expanded fadepage page-projects-new');
	}

	render(){

		return(
      <Fragment>
        <section className="intro projects">
          <Switch>
            <Route path="/projects/all/" exact component={HomeAll} />
            <Route path="/projects/web/" component={HomeWeb} />
            <Route path="/projects/branding/" component={HomeBranding} />
            <Route path="/projects/apps/" component={HomeApp} />


            <Route path="/projects/eph/" component={Eph} />
            <Route path="/projects/ryadom/" component={Ryadom} />
            <Route path="/projects/ikforum/" component={Ikforum} />
            <Route path="/projects/comcity/" component={ComCity} />
            <Route path="/projects/kakumae/" component={Kakumae} />
            <Route path="/projects/sistemavc/" component={Sistemavc} />
            <Route path="/projects/efimovbor/" component={Efimovbor} />
            <Route path="/projects/imeretinka/" component={Imeretinka} />
            <Route path="/projects/fsklider/" component={Fsklider} />
            <Route path="/projects/polyanka/" component={Polyanka} />
            <Route path="/projects/tushino/" component={Tushino} />
            <Route path="/projects/x5/" component={X5} />
            <Route path="/projects/video/" component={Video} />
            <Route path="/projects/activity/" component={Activity} />
            <Route path="/projects/x5ny/" component={X5ny} />
            <Route path="/projects/greenpolza/" component={Greenpolza} />
            <Route path="/projects/fabcab/" component={Fabcab} />
            <Route path="/projects/aresidence/" component={Aresidence} />
            <Route path="/projects/torpedo/" component={Torpedo} />
            <Route path="/projects/generalinvest/" component={GeneralInvest} />
            <Route path="/projects/botanica/" component={Botanica} />
            <Route path="/projects/x5happy/" component={X5happy} />
            <Route path="/projects/gals/" component={Gals} />
            <Route path="/projects/schastye/" component={Schastye} />
            <Route path="/projects/notfound/" component={Notfound} />
            <Route path="/projects/testtest/" component={SistemavcTest} />
            <Route path="/projects/iskra/" component={Iskra} />
            <Route path="/projects/ingrad/" component={Ingrad} />
            <Route path="/projects/x5values/" component={X5values} />
            <Route path="/projects/westgarden/" component={Westgarden} />
            <Route path="/projects/hunter-estate/" component={Hunter} />
            <Route path="/projects/academy/" component={Academy} />
            <Route path="/projects/atm-online" component={Atm} />
            <Route path="/projects/tvz" component={Tvz} />
            <Route path="/projects/kms" component={Kms} />
            <Route path="/projects/vrb" component={Vrb} />
            <Route path="/projects/f92" component={F92} />
            <Route path="/projects/bsg" component={Bsg} />
            <Route path="/projects/afimall" component={Afimall} />
            <Route path="/projects/self" component={Self} />
            <Route path="/projects/vsn" component={Vsn} />
            <Route path="/projects/rep" component={Rep} />
            <Route path="/projects/armani" component={Armani} />
            <Route path="/projects/dh" component={Dh} />
            <Route path="/projects/mts" component={Mts} />
            <Route path="/projects/cto" component={Cto} />
            <Route path="/projects/ahead" component={Ahead} />
            <Route path="/projects/genesis" component={Genesis} />
            <Route path="/projects/crimea" component={Сrimea} />
            <Route path="/projects/soyuz" component={Soyuz} />
            <Route path="/projects/dellin" component={Dellin} />
						<Route path="/projects/target" component={Target} />
            <Route path="/projects/scandipakk" component={Scandipakk} />
						<Route path="/projects/metropolia" component={Metropolia} />
	          <Route path="/projects/pp" component={Pp} />
	          <Route path="/projects/profit" component={Profit} />
						<Route path="/projects/profit" component={Profit} />
            <Route path="/projects/emotion" component={Emotion} />
            <Route path="/projects/detali" component={Detali} />
            <Route path="/projects/plus" component={Plus} />
            <Route path="/projects/x5table" component={X5Table} />
            <Route path="/projects/voshod" component={Voshod} />
            <Route path="/projects/dune" component={Dune} />
            <Route path="/projects/afipark" component={Afipark} />
            <Route path="/projects/domgorizontov" component={Domgorizontov} />
            <Route path="/projects/neshalashi" component={Neshalashi} />
            <Route component={Error} />
          </Switch>
        </section>
      </Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProjectsNew)