import React from "react";
import { Helmet } from "react-helmet";
import ym from "react-yandex-metrika";
import { Link } from "react-router-dom";

const HomeTab2 = ({children, active, active2, active3}) => {

  return(
    <div className="projectlist-new-anim">
      <div className="projectlist projects-loading projectlist-new-anim">
        <Helmet>
          <title>Проекты ' Amio</title>
        </Helmet>

        <div
          className={
            active.includes(0)
              ? "projectlist__line scroll-line active"
              : active2.includes(0)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_50">
            <Link to="/projects/neshalashi/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="61">
                <div className="projectlist__text">Платформа бренда, нейминг, фирменный стиль, создание сайта</div>
              </div>
              <div className="projectlist__title">НЕШАЛАШИ</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/dune/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="62">
                    <div className="projectlist__text">Платформа бренда, фирменный стиль, создание сайта, реклама</div>
                  </div>
                  <div className="projectlist__title">Дюна</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/afipark/" className="projectlist__item f3">
                  <div className="projectlist__bg" data-id="63">
                    <div className="projectlist__text">
                      Создание сайта
                    </div>
                  </div>
                  <div className="projectlist__title">Сиреневый парк</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/domgorizontov/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="64">
                    <div className="projectlist__text">Создание сайта</div>
                  </div>
                  <div className="projectlist__title">Дом горизонтов</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            active.includes(1)
            ? "projectlist__line scroll-line active"
            : active2.includes(1)
            ? "projectlist__line scroll-line active2"
            : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line projectlist__line_z">
              <div>
                <Link to="/projects/ahead" className="projectlist__item">
                  <div className="projectlist__bg" data-id="45">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Ahead</div>
                </Link>
              </div>
              <div>
                <Link to="/projects/bsg/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="34">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">BCG Woman Talks</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/kms" className="projectlist__item">
                  <div className="projectlist__bg" data-id="37">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Кузнецкий Мост 12</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/genesis" className="projectlist__item">
                  <div className="projectlist__bg" data-id="46">
                    <video
                      playsInline
                      autoPlay
                      poster="/cases/img/home/46_poster.jpg"
                      muted
                      loop
                    >
                      <source
                        src="/cases/img/home/46_video.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <img src="/cases/img/home/46_logo.svg" />
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Genesis</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">
            <Link to="/projects/soyuz" className="projectlist__item big">
              <div className="projectlist__bg" data-id="51">
                <div className="projectlist__text">
                  Сайт
                </div>
              </div>
              <div className="projectlist__title">ЖК Союз у метро Ботанический сад</div>
            </Link>
          </div>
        </div>

        <div
          className={
            active.includes(2)
              ? "projectlist__line scroll-line active"
              : active2.includes(2)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_50">
            <Link to="/projects/armani/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="36">
                <div className="projectlist__text">Сайт</div>
              </div>
              <div className="projectlist__title">Armani / Casa</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/pp" className="projectlist__item">
                  <div className="projectlist__bg" data-id="52">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Преображенская площадь | Regions</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/afimall/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="35">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">
                    Афимолл Сити + Маша и Медведь
                  </div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/dh" className="projectlist__item">
                  <div className="projectlist__bg" data-id="42">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Digital Heroes</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/rep" className="projectlist__item">
                  <div className="projectlist__bg" data-id="38">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Репинское</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            active.includes(3)
              ? "projectlist__line scroll-line active"
              : active2.includes(3)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/crimea/" className="projectlist__item f6">
                  <div className="projectlist__bg" data-id="47">
                    <div className="projectlist__text">
                      Сайт
                    </div>
                  </div>
                  <div className="projectlist__title">Крымская Резеденция</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link to="/projects/westgarden/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="28">
                    <div className="projectlist__text">
                      Уникальный проект сочетающий преимущества жизни на природе
                      в Москве.
                    </div>
                  </div>
                  <div className="projectlist__title">ЖК "West Garden"</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/mts/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="43">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">МТС Новый Год</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/f92/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="33">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">Федерация 92</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">
            <Link to="/projects/cto/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="44">
                <div className="projectlist__text">Сайт</div>
              </div>
              <div className="projectlist__title">X5 СТО</div>
            </Link>
          </div>
        </div>

        <div
          className={
            active.includes(4)
              ? "projectlist__line scroll-line active"
              : active2.includes(4)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <Link to="/projects/gals/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="27">
                <div className="projectlist__text">
                  Создание корпоративного сайта
                </div>
              </div>
              <div className="projectlist__title">Галс Девелопмент</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/dellin/" className="projectlist__item f6">
                  <div className="projectlist__bg" data-id="49">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                    Деловые Линии - Дело 0823
                    </div>
                  </div>

                  <div className="projectlist__title">Деловые Линии</div>
                </Link>
              </div>
              <div>
                <Link to="/projects/x5happy/" className="projectlist__item f7">
                  <div className="projectlist__bg" data-id="22">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Создание и продвижение праздничных активностей для
                      сотрудников в интернете
                    </div>
                  </div>

                  <div className="projectlist__title">Happy X5</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              {active3 && (
                <div
                  onClick={() => {
                    ym("reachGoal", "click_on_banner1");
                    return true;
                  }}
                  className="scroll-item_100 market-place"
                >
                  <Link to="/accents/marketing/" className="projectlist__item">
                    <div className="projectlist__bg">
                      <div className="projectlist__text">
                        Маркетинг в недвижимости
                      </div>
                      <video className="video2" playsInline autoPlay muted loop>
                        <source
                          src="/cases/img/home/market1.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="projectlist__title">
                      Маркетинг в недвижимости
                    </div>
                    <div className="market-stroke-wrap">
                      <div className="market-stroke">
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
                        команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
                        недвижимости
                      </div>{" "}
                    </div>
                  </Link>
                </div>
              )}
              {!active3 && (
                <div
                  onClick={() => {
                    ym("reachGoal", "click_on_banner2");
                    return true;
                  }}
                  className="scroll-item_100 market-place"
                >
                  <Link to="/accents/marketing/" className="projectlist__item">
                    <div className="projectlist__bg">
                      <div className="projectlist__text">
                        Маркетинг в недвижимости
                      </div>
                      <video className="video1" playsInline autoPlay muted loop>
                        <source
                          src="/cases/img/home/market2.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="projectlist__title">
                      Маркетинг в недвижимости
                    </div>
                    <div className="market-stroke-wrap">
                      <div className="market-stroke">
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество
                        продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                        недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
                        количество продаж
                      </div>{" "}
                    </div>
                  </Link>
                </div>
              )}
              {/* <div className="scroll-item_100">
                <Link to="/projects/ingrad/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="23">
                    <div className="projectlist__text">Создание дизайна корпоративного сайта</div>
                  </div>
                  <div className="projectlist__title">Инград</div>  
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/westgarden/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="28">
                    <div className="projectlist__text">Уникальный проект сочетающий преимущества жизни на природе в Москве.</div>
                  </div>
                  <div className="projectlist__title">ЖК "West Garden"</div>
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className={
            active.includes(5)
              ? "projectlist__line scroll-line active"
              : active2.includes(5)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/iskra/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="25">
                    <div className="projectlist__text">
                      Видео-сайт для квартала на Ленинградском проспекте
                    </div>
                  </div>
                  <div className="projectlist__title">Искра-Парк</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/polyanka/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="10">
                    <div className="projectlist__text">
                      Сайт для уникального комплекса элитных особняков в
                      историческом центре Москвы
                    </div>
                  </div>
                  <div className="projectlist__title">Полянка 44</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/torpedo/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="19">
                    <div className="projectlist__text">
                      Сайт для легендарного футбольного клуба «Торпедо"
                    </div>
                  </div>
                  <div className="projectlist__title">Торпедо</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link
                  to="/projects/generalinvest/"
                  className="projectlist__item"
                >
                  <div className="projectlist__bg" data-id="20">
                    <div className="projectlist__text">
                      Дизайн сайта инвестиционной компании с богатой историей и
                      итальянскими корнями
                    </div>
                  </div>
                  <div className="projectlist__title">General Invest</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">
            <Link to="/projects/greenpolza/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="16">
                <div className="projectlist__text">
                  Визуальная концепция и создание материалов для уникального
                  продукта Банка Home Credit
                </div>
              </div>
              <div className="projectlist__title">Зеленая польза</div>
            </Link>
          </div>
        </div>

        <div
          className={
            active.includes(6)
              ? "projectlist__line scroll-line active"
              : active2.includes(6)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_50">
            <Link to="/projects/x5ny/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="13">
                <div className="projectlist__text">
                  Специальный новогодний портал для X5 Retail Group. <br />
                  Лучший диджитал проект 2018, по признанию WOW! HR
                </div>
              </div>
              <div className="projectlist__title">Помощник Деда Мороза</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div>
                <Link
                  to="/projects/efimovbor/"
                  className="projectlist__item f5"
                >
                  <div className="projectlist__bg" data-id="7">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Комплексный проект по созданию и продвижению бренда отеля
                    </div>
                  </div>
                  <div className="projectlist__title">Ефимов Бор</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                {/*<Link to="/projects/imeretinka/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="8">
                    <div className="projectlist__text">Создание сайта самого заметного объекта недвижимости Сочи</div>
                  </div>
                  <div className="projectlist__title">Имеретинский</div>  
                </Link>*/}
                <Link to="/projects/atm-online/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="31">
                    <div className="projectlist__text">ATM online</div>
                  </div>
                  <div className="projectlist__title">ATM online</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/schastye/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="24">
                    <div className="projectlist__text">
                      Сайт для зонтичного бренда
                    </div>
                  </div>
                  <div className="projectlist__title">Счастье</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/video/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="12">
                    <div className="projectlist__text">
                      Самое важное из искусств в нашем исполнении
                    </div>
                  </div>
                  <div className="projectlist__title">Видео</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            active.includes(7)
              ? "projectlist__line scroll-line active"
              : active2.includes(7)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/x5/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="14">
                    <div className="projectlist__text">
                      Социальный проект X5&nbsp;Retail&nbsp;Group
                    </div>
                  </div>
                  <div className="projectlist__title">Корзина доброты</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link to="/projects/activity/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="15">
                    <div className="projectlist__text">
                      Игры, конкурсы, активности
                    </div>
                  </div>
                  <div className="projectlist__title">Активности</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/eph/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="1">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Корпоративный сайт для швейцарского инвестиционного
                      холдинга
                    </div>
                  </div>
                  <div className="projectlist__title">
                    Eastern Property Holdings
                  </div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/ryadom/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="2">
                    <div className="projectlist__text">
                      Сайт для агентства недвижимости, которое всегда рядом
                    </div>
                  </div>
                  <div className="projectlist__title">Рядом</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">
            <Link to="/projects/ikforum/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="3">
                <div className="projectlist__text">
                  Фирменный стиль и корпоративный сайт для инвестиционной
                  компании
                </div>
              </div>
              <div className="projectlist__title">ИК “Форум”</div>
            </Link>
          </div>
        </div>

        <div
          className={
            active.includes(8)
              ? "projectlist__line scroll-line active"
              : active2.includes(8)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_50">
            <Link to="/projects/vrb" className="projectlist__item big">
              <div className="projectlist__bg" data-id="39">
                <div className="projectlist__text">Сайт</div>
              </div>
              <div className="projectlist__title">Врубеля 4</div>
            </Link>
          </div>

          <div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/aresidence/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="18">
                    <div className="projectlist__text">
                      Сайт для ансамбля клубных особняков
                    </div>
                  </div>
                  <div className="projectlist__title">A-Residence</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/kakumae/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="5">
                    <div className="projectlist__text">
                      Сайт для уникальной марины в Финском заливе
                    </div>
                  </div>
                  <div className="projectlist__title">Haven Kakumäe</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/vsn" className="projectlist__item">
                  <div className="projectlist__bg" data-id="40">
                    <div className="projectlist__text">
                      Разработка бренд-стратегии, логотипа, фирменного стиля для
                      агентства VSN Realty
                    </div>
                  </div>
                  <div className="projectlist__title">VSN Realty</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/tvz/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="32">
                    <div className="projectlist__text">Сайт</div>
                  </div>
                  <div className="projectlist__title">
                    Тульский винокуренный завод
                  </div>
                </Link>
              </div>
            </div>
          </div>


        </div>

        <div
          className={
            active.includes(9)
              ? "projectlist__line scroll-line active"
              : active2.includes(9)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line projectlist__line_z">
              <div>
                <Link to="/projects/apps" className="projectlist__item">
                  <div className="projectlist__bg" data-id="48">
                    <video
                      playsInline
                      autoPlay
                      poster="/cases/img/home/48_poster.jpg"
                      muted
                      loop
                    >
                      <source
                        src="/cases/img/home/48.mp4"
                        type="video/mp4"
                      />
                    </video>

                    <img src="/cases/img/home/48_icon.svg" />
                  </div>
                  <div className="projectlist__title">#ПРИЛОЖЕНИЯ</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link to="/projects/botanica/" className="projectlist__item f4">
                  <div className="projectlist__bg" data-id="21">
                    <div className="projectlist__text">
                      Создание сайта дома премиум-класса, расположенного в самом
                      центре Петроградской стороны
                    </div>
                  </div>
                  <div className="projectlist__title">ЖК “Ботаника”</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line projectlist__line_z">
              <div>
                <Link to="/projects/ingrad/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="23">
                    <div className="projectlist__text">
                      Создание дизайна корпоративного сайта
                    </div>
                  </div>
                  <div className="projectlist__title">Инград</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link
                  to="/projects/hunter-estate/"
                  className="projectlist__item f2"
                >
                  <div className="projectlist__bg" data-id="30">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Фирменный стиль и сайт
                    </div>
                  </div>
                  <div className="projectlist__title">Усадьба охотника</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_200"></div>
            </div>
          </div>
          
          <div className="scroll-item_50">
            {active3 && (
              <div
                onClick={() => {
                  ym("reachGoal", "click_on_banner3");
                  return true;
                }}
                className="scroll-item_100 market-place"
              >
                <Link to="/accents/marketing/" className="projectlist__item">
                  <div className="projectlist__bg">
                    <div className="projectlist__text">
                      Маркетинг в недвижимости
                    </div>
                    <video className="video3" playsInline autoPlay muted loop>
                      <source
                        src="/cases/img/home/market3.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="projectlist__title">
                    Маркетинг в недвижимости
                  </div>
                  <div className="market-stroke-wrap">
                    <div className="market-stroke">
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость
                      звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
                      низкая стоимость звонка
                    </div>{" "}
                  </div>
                </Link>
              </div>
            )}
            {!active3 && (
              <div
                onClick={() => {
                  ym("reachGoal", "click_on_banner4");
                  return true;
                }}
                className="scroll-item_50 market-place market-place2"
              >
                <Link to="/accents/marketing/" className="projectlist__item big">
                  <div className="projectlist__bg">
                    <div className="projectlist__text">
                      Маркетинг в недвижимости
                    </div>
                    <video className="video4" playsInline autoPlay muted loop>
                      <source
                        src="/cases/img/home/market4.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="projectlist__title">
                    Маркетинг в недвижимости
                  </div>
                  <div className="market-stroke-wrap">
                    <div className="market-stroke">
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
                      скорость
                      реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
                      недвижимости
                    </div>{" "}
                  </div>
                </Link>
              </div>
            )}

          </div>
                
        </div>

        <div
          className={
            active.includes(10)
              ? "projectlist__line scroll-line active"
              : active2.includes(10)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_200">
            <Link to="/projects/scandipakk/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="50">
                <div className="projectlist__text">Полный комплекс работ по ребрендингу всемирно известной компании по производству одноразовой упаковки для продуктов питания.</div>
              </div>
              <div className="projectlist__title">Скандипакк</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link
                  to="/projects/sistemavc/"
                  className="projectlist__item f1"
                >
                  <div className="projectlist__bg" data-id="6">
                    <div className="projectlist__text">
                      Разработка фирменного стиля для венчурного фонда АФК
                      «СИСТЕМА»
                    </div>
                  </div>
                  <div className="projectlist__title">Systema VC</div>
                </Link>
              </div>
              <div>
                <div className="scroll-item_200">
                  <Link to="/projects/academy/" className="projectlist__item f3">
                    <div className="projectlist__bg" data-id="29">
                      <div className="projectlist__text">
                        Создание фирменного стиля
                      </div>
                    </div>
                    <div className="projectlist__title">Х5. Цифровая академия</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link to="/projects/self" className="projectlist__item">
                  <div className="projectlist__bg" data-id="41">
                    <div className="projectlist__text">
                      Полный комплекс работ по созданию бренда для жилого комплекса
                    </div>
                  </div>
                  <div className="projectlist__title">SELF Парковый квартал</div>
                </Link>
              </div>
              <div>
                <div className="scroll-item_200">
                  <Link to="/projects/profit/" className="projectlist__item">
                    <div className="projectlist__bg" data-id="53">
                      <div className="projectlist__text">
                        Разработка нейминга, фирменного стиля, лендинга
                      </div>
                    </div>
                    <div className="projectlist__title">Ipoteka Profit</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

				<div
          className={
            active.includes(11)
              ? "projectlist__line scroll-line active"
              : active2.includes(11)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link to="/projects/target/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="54">
                    <div className="projectlist__text">
                      Сайт
                    </div>
                  </div>
                  <div className="projectlist__title">Target Point</div>
                </Link>
              </div>
              <div>
                <div className="scroll-item_200">
                  <Link to="/projects/metropolia" className="projectlist__item">
                    <div className="projectlist__bg" data-id="55">
                      <div className="projectlist__text">
                        Сайт
                      </div>
                    </div>
                    <div className="projectlist__title">Метрополия</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link to="/projects/detali" className="projectlist__item">
                  <div className="projectlist__bg" data-id="56">
                    <div className="projectlist__text">
                    ЖК «Детали»
                    </div>
                  </div>
                  <div className="projectlist__title">ЖК «Детали»</div>
                </Link>
              </div>
              <div>
                <div className="scroll-item_200">
                  <Link to="/projects/plus" className="projectlist__item">
                    <div className="projectlist__bg" data-id="58">
                      <div className="projectlist__text">
                        Брендинг
                      </div>
                    </div>
                    <div className="projectlist__title">Plus</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="scroll-item_200">
            <Link to="/projects/x5table" className="projectlist__item big">
              <div className="projectlist__bg" data-id="59">
                <div className="projectlist__text">
                  Сайт
                </div>
              </div>
              <div className="projectlist__title">X5 Все за одним столом!</div>
            </Link>
          </div>

        </div>

        <div
          className={
            active.includes(12)
              ? "projectlist__line scroll-line active"
              : active2.includes(12)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_200">
            <Link to="/projects/voshod" className="projectlist__item big">
              <div className="projectlist__bg" data-id="60">
                <div className="projectlist__text">
                  Сайт
                </div>
              </div>
              <div className="projectlist__title">Voshod</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div className="scroll-item_200">

              </div>
              <div>
                <div className="scroll-item_200">

                </div>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_200">

              </div>
              <div>
                <div className="scroll-item_200">

                </div>
              </div>
            </div>
          </div>
        </div>


        {children}
      </div>
    </div>
  )
}

export default HomeTab2