import React, { Component, Fragment } from 'react';
import { BrowserRouter ,  Route, Link, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import mapStateToProps from './store/mapStateToProps';
import mapDispatchToProps from './store/mapDispatchToProps';

import Home from './components/Home/Home';
import HomeNew from './components/HomeNew/HomeNew'
// import Projects from './components/Projects/Projects';
// import ProjectsNav from './components/Projects/ProjectsNav';
import Sources from './components/Sources/Sources';
import About from './components/About/About';
// import Contact from './components/Contact/Contact';
import ContactEmpty from './components/Contact/ContactEmpty';
import { YMInitializer } from 'react-yandex-metrika';
import Error from './components/Error';
import Promote from './components/Promote'
import ProjectsAll from './components/ProjectsAll'
import ProjectsNew from './components/Projects/ProjectsNew';
import NotFormat from './components/NotFormat/NotFormat';

import './css/app.css';
import './css/source-mobile.css';
import './css/source-marketing.css';
import './css/source-404.css';
import './css/source-ccp.css';
import './css/source-reality.css';
import './css/source-banks.css';
import './css/source-audit.css';

import $ from 'jquery';

import {Helmet} from 'react-helmet';
// import FormBtn from "./components/helpers/form-btn";
import lazyLoad from './components/helpers/lazyLoad';

import SourcesMarketingForm from './components/Sources/SourcesMarketingForm'
import EmailForm from "./components/Sources/mail-form";
import TenderForm from "./components/Sources/tender-form";

class App extends Component {

	state = {
		windowWidth: window.innerWidth,
    tab: 1
	}

	componentDidMount() {
		lazyLoad();
		this.handleResize();
		window.addEventListener('resize', this.handleResize);

		$(window).scroll(function(){
			let scr = $(window).scrollTop();

			if(scr > 0){
				$('body').addClass('fixed');
			}
			else{
				$('body').removeClass('fixed');
      }
			lazyLoad();
    });
		var lastScrollTop = 0;

		window.addEventListener("scroll", function(){
			var st = window.pageYOffset || document.documentElement.scrollTop; 
			if (st > lastScrollTop){
			$('body').removeClass('fixedd');
			} else {
				if(!$('body').hasClass("fixedd")){
				$('body').addClass('fixedd');
				}
			}
			lastScrollTop = st <= 0 ? 0 : st; 
			lazyLoad();
		}, false);
  }
  
  componentDidUpdate(){
		lazyLoad();
  }

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		
		this.setState({
			windowWidth: window.innerWidth
		});

		if(this.state.windowWidth < 1000){
			this.props.setVersion('mobile');
		}
		else{
			this.props.setVersion('desctope');
		}
	}

	render() {

		let pageClass = '';
		if(this.props.pageId === 'contact'){
			pageClass = 'page-contacts';
		}

		if(this.props.pageId === 'about'){
			pageClass = 'page-about';
		}

		if(this.props.pageId === 'home'){
			pageClass = 'page-index';
		}

		if(this.props.pageId === 'projects'){
			pageClass = 'page-projects';
		}

    if(this.props.pageId === 'advertising'){
			pageClass = 'page-advertising';
		}
		
		if(this.props.pageId === 'sources' || this.props.pageId === 'sources-mobile' || this.props.pageId === 'sources-marketing'){
			pageClass = 'page-sources';
		}
		
		if(this.props.pageId === 'notformat'){
			pageClass = 'page-notformat';
		}

		return (
      <Fragment>

        <div className={`contacts-call-me-popup forms-modal  ${this.state.tab===3 ? 'forms-modal2' : ''} ${this.props.popup ? 'active' : ''}`}>
          <div className="tab-nav">
            <div data-active={this.state.tab === 1} onClick={() => this.setState({tab: 1})}><span>Мы сами перезвоним</span></div>
            <div data-active={this.state.tab === 2} onClick={() => this.setState({tab: 2})}><span>Отправить весточку</span></div>
            <div data-active={this.state.tab === 3} onClick={() => this.setState({tab: 3})}><span>Пригласить в тендер</span></div>
          </div>
          {this.state.tab === 1 && <div className="forms-modal__in"><SourcesMarketingForm close={() => this.props.openPopup(false)} callback={true} /></div>}
          {this.state.tab === 2 && <div className="forms-modal__in"><EmailForm close={() => this.props.openPopup(false)} /></div>}
          {this.state.tab === 3 && <div className="forms-modal__in"><TenderForm close={() => this.props.openPopup(false)} /></div>}
          <div className="contacts-call-me-popup__close" onClick={() => this.props.openPopup(false)}></div>
        </div>

        <BrowserRouter>
          <div className={'body ' + pageClass}>
            <YMInitializer accounts={[86785357]} />

            <Helmet>
              <title>Amio. Detto, fatto.</title>
            </Helmet>

            <header>
              <menu className="main">
                <NavLink to="/projects/all/" onClick={() => this.props.changeCase(null)}>Создаём</NavLink>
                {this.props.pageId !== 'home' && <NavLink to="/accents/">Акценты</NavLink> }
                {this.props.pageId !== 'home' && <NavLink to="/about/">О нас</NavLink>}
                { /* <NavLink to="/not-format/">Неформат</NavLink> */ }
                {this.props.pageId !== 'home' && <NavLink to="/contacts/">Контакты</NavLink>}
                <NavLink to="/advertising/">Продвигаем</NavLink>
              </menu>

              <div className="sources-tags-overlay">
                <nav className="tags sources-tags">
                  <NavLink to="/accents/banks/"><span data-index="banks">Банки</span></NavLink>
                  <NavLink to="/accents/marketing/"><span data-index="marketing">Комплексный маркетинг в недвижимости</span></NavLink>
                  <NavLink to="/accents/404/"><span data-index="404">404</span></NavLink>
                  <NavLink to="/accents/ccp/"><span data-index="ccp">CCP</span></NavLink>
                  <NavLink to="/accents/reality/"><span data-index="reality">Дополненная реальность</span></NavLink>
                  <NavLink to="/accents/audit/"><span data-index="audit">Аудит контекстной рекламы</span></NavLink>
                </nav>
              </div>

              {/*<ProjectsNav/>*/}
          
              <Link to="/" className="logo">
                <i></i>
                {this.props.pageId === 'projects' && <span>Creative</span>}
                {this.props.pageId === 'advertising' && <span>Advertising</span>}
              </Link>
            </header>

            <Switch>
              <Route exact path="/homenew" component={HomeNew} />
              <Route exact path="/advertising" component={Promote} />
              <Route exact path="/" component={Home} />
              <Route path="/accents/" component={Sources} />
              <Route path="/about/" component={About} />
              <Route path="/contacts/" component={ContactEmpty} />
              <Route path="/projects/" component={ProjectsNew} />
              <Route path="/projects-new/:id/" component={ProjectsAll} />
              <Route path="/not-format/" component={NotFormat} />
              <Route component={Error} />
            </Switch>

            {/*<Contact/>*/}

            <div className="slogan">
              <span>
                Идея. Разработка. Запуск
              </span>
              <svg width="80" height="23" viewBox="0 0 80 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.833496 11.5H77.9168" stroke="black" strokeWidth="2"/>
                <path d="M67.9341 1.60059L77.8336 11.5001L67.9341 21.3996" stroke="black" strokeWidth="2"/>
              </svg>
              <span>Космос</span>
            </div>

            <div className="l-trigger"></div>
          </div>
        </BrowserRouter>
      </Fragment>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App)
