import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooterNew from '../CaseFooterNew';
import HelmetSite from '../HelmetSite';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.min.css";

class Afipark extends Component{
  swiperRef = React.createRef();
  state = {
		preview: true,
		play: false,
		mobile: window.innerWidth <= 1000
	};

	resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    var video = document.getElementById("video");
    $(".videoBlock__btn").click(function () {
      $(".videoBlock__btn").toggleClass("videoBlock__btn_hidden");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
		if(this.props.pathname == '/projects/afipark/'){
			this.setState({preview: false});
			this.props.setProjectName('Afipark');
			$('body').addClass('case-expanded hash-afipark');
			$(window).scrollTop($('#case-afipark').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-afipark');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-afipark');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-afipark">

        <div className="screen">
          <div className="topScreen">
            <div className="topScreen__bg" style={{backgroundImage: `url('/cases/img/afipark/bg-top.webp')`}}>
              {!this.state.mobile && (<video playsInline autoPlay poster="/cases/img/afipark/video_bg.webp" muted loop>
                <source src="/cases/img/afipark/video_bg.mp4" type="video/mp4" />
              </video>)}
            </div>
            <div className="topScreen__title"><img src="/cases/img/afipark/title.svg" alt="" /></div>
          </div>
          <div className="aboutProject">
            <div className="content">
              <div className="aboutProject__wrap">
                <div className="aboutProject__left">
                  <div className="aboutProject__title title">Сиреневый Парк</div>
                  <div className="aboutProject__desc">
                    <div>
                      <div>Клиент</div>
                      <div>AFI Development</div>
                    </div>
                    <div>
                      <div>Виды&nbsp;работ</div>
                      <div>Создание сайта</div>
                    </div>            
                  </div>          
                </div>
                <div className="aboutProject__text text">
                  Креативная концепция нового сайта состоит в&nbsp;том, чтобы сделать сайт не&nbsp;только  информативным, функциональным, интерактивным. Сайтом который раскроет преимущества «Сиреневого парка», его&nbsp;уникальность, но&nbsp;и&nbsp;создаст эмоциональную связь с&nbsp;пользователем, вовлечёт его во&nbsp;взаимодействие и&nbsp;тем самым более глубокое знакомство с&nbsp;проектом. 
                  <br/><br/>
                  С помощью дизайна и&nbsp;копирайтинга рассказать о&nbsp;проекте так, чтобы вызвать к&nbsp;нему эмоциональную привязанность и&nbsp;желание жить именно здесь. 
                  <br/><br/>
                  В основе дизайна – яркая типографика и&nbsp;фирменные цвета. Вся страница выстроена на&nbsp;акцентной типографике. Дизайн понятный, информация подаётся постепенно и&nbsp;интересно при&nbsp;помощи анимации. 
                  <br/><br/>
                  Сайт сохраняет чистоту и&nbsp;ясность заданного стиля бренда и&nbsp;чётко разъясняет основные идеи, заложенные в&nbsp;него.
                  <br/><br/>
                  Страница начинается с&nbsp;эмоциональной обложки. Кроме образной иллюстрации, такой заход хорошо запомнится, так как фокус внимания не на&nbsp;привычном рендере, а&nbsp;девушке, созданной и&nbsp;анимированной нейросетью. 
                  <br/><br/>
                  Тексты лаконичные, хорошо структурированы. Стилистика – динамичная и&nbsp;современная. 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox">
            <div className="gridBox__item gridBox__item_1"><img src="/cases/img/afipark/img1.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_2" style={{backgroundImage: `url('/cases/img/afipark/img2.webp')`}}></div>
            <div className="gridBox__item gridBox__item_3"><img src="/cases/img/afipark/img3.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_4" style={{backgroundImage: `url('/cases/img/afipark/img4.webp')`}}>
              {!this.state.mobile && (<video playsInline autoPlay poster="/cases/img/afipark/video_bg.webp" muted loop>
                <source src="/cases/img/afipark/video_bg2.mp4" type="video/mp4" />
              </video>)}
              <div className="videoBtn">
                <div className="videoBtn__play">
                  Видео<br/>
                  о проекте
                </div>
                <div className="videoBtn__text">
                  Жилой комплекс<br/>
                  на востоке Москвы <br/>
                  для счастливой жизни всей семьи 
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_5">
              <div className="gridChild">
                <div className="gridChild__item gridChild__item_1" style={{backgroundImage: `url('/cases/img/afipark/img19.webp')`}}>Двор<br/>без машин</div>
                <div className="gridChild__item gridChild__item_2" style={{backgroundImage: `url('/cases/img/afipark/img20.webp')`}}>Входы<br/>без ступеней</div>
                <div className="gridChild__item gridChild__item_3" style={{backgroundImage: `url('/cases/img/afipark/img21.webp')`}}>сквозные<br/>проходы</div>
                <div className="gridChild__item gridChild__item_4" style={{backgroundImage: `url('/cases/img/afipark/img22.webp')`}}>Скоростные<br/>лифты</div>
                <div className="gridChild__item gridChild__item_5">
                  Акцент проекта - уютный внутренний двор в каждом корпусе, вокруг которого строится композиция жилого комплекса «Двор без машин»
                </div>
                <div className="gridChild__item gridChild__item_6" style={{backgroundImage: `url('/cases/img/afipark/img23.webp')`}}>Зоны<br/>ожидания<br/>и&nbsp;отдыха</div>
                <div className="gridChild__item gridChild__item_7" style={{backgroundImage: `url('/cases/img/afipark/img24.webp')`}}>Подземный<br/>паркинг</div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_6"><img src="/cases/img/afipark/title.svg" alt="" /></div>
            <div className="gridBox__item gridBox__item_7" style={{backgroundImage: `url('/cases/img/afipark/img5.webp')`}}></div>
            <div className="gridBox__item gridBox__item_8">
              <img src="/cases/img/afipark/img6.webp" alt="" />
              <img src="/cases/img/afipark/img7.webp" alt="" />
              <img src="/cases/img/afipark/img8.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_9">
              <div className="videoBlock">
                <div className="videoBlock__btn" style={{ backgroundImage: `url('/cases/img/afipark/play.svg')` }}></div>
                <video id="video" poster="/cases/img/afipark/poster.webp" loop preload="metadata">
                  <source src="/cases/img/afipark/video2.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_10">
              <img src="/cases/img/afipark/img9.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_11">
              <img src="/cases/img/afipark/img10.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_12">
              <div className="imagesBlock">
                <div className="imagesBlock__item">
                  <div className="imagesBlock__text">заряжаем</div>
                  <div className="imagesBlock__img"><img src="/cases/img/afipark/img11.webp" alt="" /></div>
                  <div className="imagesBlock__text">энергией</div>
                </div>
                <div className="imagesBlock__item">
                  <div className="imagesBlock__text">лучше</div>
                  <div className="imagesBlock__img"><img src="/cases/img/afipark/img12.webp" alt="" /></div>
                  <div className="imagesBlock__text">вместе</div>
                </div>
                <div className="imagesBlock__item">
                  <div className="imagesBlock__text">дарим</div>
                  <div className="imagesBlock__img"><img src="/cases/img/afipark/img13.webp" alt="" /></div>
                  <div className="imagesBlock__text">лёгкость</div>
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_13">
              <div className="gridSmall">
                <div className="gridSmall__text text">                  
                  Мобильная версия сайта жилого комплекса полностью повторяет функционал основной версии, обеспечивая пользователям доступ ко всем необходимым функциям в любое время и в любом месте. 
                  <br/><br/>
                  Интерфейс оптимизирован для удобства использования на мобильных устройствах, что делает навигацию простой и интуитивной. При этом визуальный дизайн сохраняет эстетическую привлекательность, отражая фирменный стиль проекта и создавая гармоничное восприятие даже на&nbsp;небольших экранах.
                </div>
                <div className="gridSmall__img"><img src="/cases/img/afipark/img14.webp" alt="" /></div>                
              </div>
            </div>
            <div className="gridBox__item gridBox__item_14">
              <img src="/cases/img/afipark/img15.webp" alt="" />
              <img src="/cases/img/afipark/img16.webp" alt="" />
              <img src="/cases/img/afipark/img17.webp" alt="" />
              <img src="/cases/img/afipark/img18.webp" alt="" />
            </div>
          </div>
        </div>        
			</div>  

      <CaseFooterNew 
				to={ this.props.next ? this.props.next.url: "/projects/dune/"  }
				title= { this.props.next ? this.props.next.name: `Сиреневый Парк`   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/afipark/afipark.webp" }
			/>
      <HelmetSite title="Сиреневый Парк" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Сиреневый Парк</div>
				<div className="tags"><span>сайт</span><span>Копирайтинг</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Afipark)