import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooterNew from '../CaseFooterNew';
import HelmetSite from '../HelmetSite';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "../../../../node_modules/swiper/swiper-bundle.min.css";

class Dune extends Component{
  swiperRef = React.createRef();
  state = {
		preview: true,
		play: false,
		mobile: window.innerWidth <= 1000
	};

	resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    var video = document.getElementById("video");
    $(".videoBlock__btn").click(function () {
      $(".videoBlock__btn").toggleClass("videoBlock__btn_hidden");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
		if(this.props.pathname == '/projects/dune/'){
			this.setState({preview: false});
			this.props.setProjectName('Dune');
			$('body').addClass('case-expanded hash-dune');
			$(window).scrollTop($('#case-dune').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-dune');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);


    $('.fixedMenu')
			.addClass('fixedMenu_original')
			.clone()
			.insertAfter('.fixedMenu')
			.addClass('fixedMenu_cloned')
			.removeClass('fixedMenu_original');

    window.addEventListener('scroll', this.resizeMenu);

    let last_id;
		let top_menu = $('.fixedMenu_cloned');
		let menu_height = top_menu.outerHeight(true);
		let menu_items = top_menu.find('.fixedMenu__item');
		let scroll_items = menu_items.map(function(){
			let item = $("#" + $(this).attr('data-id'));
			if (item.length){
				return item;
			}
		});

		menu_items.click(function(e){
			let id = $(this).attr('data-id'),
			offset_top = $('#' + id).offset().top;
			$('html, body').stop().animate({
				scrollTop: offset_top
			}, 300);
			e.preventDefault();
		});

		window.addEventListener('scroll', function(){
			let from_top = $(this).scrollTop() + menu_height;
			let cur = scroll_items.map(function(){
				if ($(this).offset().top < from_top){
					return this;
				}
			});
			cur = cur[cur.length - 1];
			let id = cur && cur.length ? cur[0].id : '';
			if (last_id !== id){
				last_id = id;
				$('.fixedMenu__item').removeClass('fixedMenu__item_active');
				$(".fixedMenu__item[data-id='" + id + "']").addClass('fixedMenu__item_active');
			}
		});
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-dune');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
		window.removeEventListener('resize', this.resizeMenu);
	}

  resizeMenu() {
    const original = $('.fixedMenu_original');
		const cloned = $('.fixedMenu_cloned');
		const header = window.innerWidth <= 1000 ? ($('header').outerHeight() - 30) : $('header').outerHeight();
		const headerVisible = $('body').hasClass('fixedd');
    if(original.length) {
      if ($(window).scrollTop() >= (original.offset().top)){
        cloned.css({
          'top': headerVisible ? header : 0,
          'width': original.css('width'),
          'display': 'block',
          'left': original.offset().left+'px'
        });
        original.css('visibility', 'hidden');
      } else {
        cloned.css('display', 'none');
        original.css('visibility', 'visible');
      }
    }
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-dune">

        <div className="screen">
          <div className="topScreen">
            <div className="topScreen__bg" style={{backgroundImage: `url('/cases/img/dune/bg-top.webp')`}} />
            <div className="topScreen__word topScreen__word_1">Д</div>
            <div className="topScreen__word topScreen__word_2">Ю</div>
            <div className="topScreen__word topScreen__word_3">Н</div>
            <div className="topScreen__word topScreen__word_4">А</div>
          </div>
          <div className="aboutProject">
            <div className="content">
              <div className="aboutProject__title title">Жилой комплекс “Дюна”</div>
              <div className="aboutProject__desc">
                <div>
                  <div>Клиент</div>
                  <div>Trend Group</div>
                </div>
                <div>
                  <div>Виды&nbsp;работ</div>
                  <div>
                    <div className="tabs">
                      <div>Платформа бренда</div>
                      <div>Фирменный стиль</div>
                      <div>Создание сайта</div>
                      <div>Реклама</div>
                    </div>
                  </div>
                </div>            
              </div>          
            </div>
          </div>
        </div>
        <div className="screen workType">
          <div className="content">
            <div className="workType__wrap">
              <div className="workType__title title">Фирменный стиль</div>
              <div className="workType__desc text">
                Разработка фирменного стиля для жилого комплекса, находящегося на стыке классов комфорт+ и бизнес, требует особого подхода.
                <br/><br/>
                Основой графической концепции стали мотивы песчаных волн на дюнах, создающие динамичное и мягкое восприятие. 
                <br/><br/>
                В стиле используются три базовых цвета — золото, серебро и бронза. Эти оттенки взяты из архитектурных решений комплекса и помогают гибко передавать уровень класса объекта, от теплого уюта до сдержанной элегантности.
              </div>
            </div>
          </div>
        </div>
        <div className="fixedMenu">
          <div className="fixedMenu__wrap">
            <div className="fixedMenu__item" data-id="corporate-style">Фирменный стиль</div>
            <div className="fixedMenu__item" data-id="corporate-site">Сайт</div>
            <div className="fixedMenu__item" data-id="corporate-advert">Реклама</div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox gridBox_style" id="corporate-style">
            <div className="gridBox__item gridBox__item_1">
              ДЮНА
            </div>
            <div className="gridBox__item gridBox__item_2" style={{backgroundImage: `url('/cases/img/dune/lines.svg')`}}></div>
            <div className="gridBox__item gridBox__item_3" style={{backgroundImage: `url('/cases/img/dune/img2.webp')`}}></div>
            <div className="gridBox__item gridBox__item_4" style={{backgroundImage: `url('/cases/img/dune/img3.webp')`}}></div>
            <div className="gridBox__item gridBox__item_5">
              <img src="/cases/img/dune/img4.webp" alt="" />
              <img src="/cases/img/dune/img5.webp" alt="" />
              <img src="/cases/img/dune/img6.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_6"><img src="/cases/img/dune/img7.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_7" style={{backgroundImage: `url('/cases/img/dune/img8.webp')`}}></div>
            <div className="gridBox__item gridBox__item_8" style={{backgroundImage: `url('/cases/img/dune/img9.webp')`}}></div>
            <div className="gridBox__item gridBox__item_9">
              <div className="gridChild">
                <div className="gridChild__item gridChild__item_1" style={{backgroundImage: `url('/cases/img/dune/img10.webp')`}}></div>
                <div className="gridChild__item gridChild__item_2" style={{backgroundImage: `url('/cases/img/dune/img11.webp')`}}></div>
                <div className="gridChild__item gridChild__item_3"><img src="/cases/img/dune/img12.svg" alt="" /></div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_10">
              <img src="/cases/img/dune/img-j1.webp" alt="" />
              <img src="/cases/img/dune/img-j2.webp" alt="" />
              <img src="/cases/img/dune/img-j3.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_11" style={{backgroundImage: `url('/cases/img/dune/img13.webp')`}}></div>
            <div className="gridBox__item gridBox__item_12">
              <div className="colorsGrid">
                <div className="colorsGrid__item colorsGrid__item_1">
                  <div className="colorsGrid__top">
                    HEX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;D1B768<br/>
                    CMYK&nbsp;&nbsp;20&nbsp;&nbsp;24&nbsp;&nbsp;71&nbsp;&nbsp;0<br/>
                    RGB&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;209&nbsp;&nbsp;&nbsp;183&nbsp;&nbsp;&nbsp;104
                  </div>
                  <div className="colorsGrid__bottom">Золото</div>                  
                </div>
                <div className="colorsGrid__item colorsGrid__item_2">
                  <div className="colorsGrid__top">
                    HEX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B99170<br/>
                    CMYK&nbsp;&nbsp;27&nbsp;&nbsp;42&nbsp;&nbsp;59&nbsp;&nbsp;&nbsp;3 <br/>
                    RGB&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;185&nbsp;&nbsp;&nbsp;145&nbsp;&nbsp;&nbsp;112
                  </div>
                  <div className="colorsGrid__bottom">Бронза</div>                  
                </div>
                <div className="colorsGrid__item colorsGrid__item_3">
                  <div className="colorsGrid__top">
                    HEX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;423D35<br/>
                    CMYK&nbsp;&nbsp;63&nbsp;&nbsp;59&nbsp;&nbsp;67&nbsp;&nbsp;52  <br/>
                    RGB&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;66&nbsp;&nbsp;&nbsp;61&nbsp;&nbsp;53
                  </div>
                  <div className="colorsGrid__bottom">Тёплый серый</div>                  
                </div>
                <div className="colorsGrid__item colorsGrid__item_4">
                  <div className="colorsGrid__top">
                    HEX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B8BDB8<br/>
                    CMYK&nbsp;&nbsp;29&nbsp;&nbsp;20&nbsp;&nbsp;25&nbsp;&nbsp;&nbsp;0<br/>
                    RGB&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;184&nbsp;&nbsp;&nbsp;189&nbsp;&nbsp;&nbsp;184
                  </div>
                  <div className="colorsGrid__bottom">Серебро</div>                  
                </div>
                <div className="colorsGrid__item colorsGrid__item_5">
                  <div className="colorsGrid__top">
                    HEX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;191815<br/>
                    CMYK&nbsp;&nbsp;71&nbsp;&nbsp;66&nbsp;&nbsp;68&nbsp;&nbsp;80  <br/>
                    RGB&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25&nbsp;&nbsp;24&nbsp;&nbsp;21
                  </div>
                  <div className="colorsGrid__bottom">Графит</div>                  
                </div>
                <div className="colorsGrid__item colorsGrid__item_6">
                  <div className="colorsGrid__top"></div>
                  <div className="colorsGrid__bottom">Белый</div>                  
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_13"><img src="/cases/img/dune/img14.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_14"><img src="/cases/img/dune/img15.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_15" style={{backgroundImage: `url('/cases/img/dune/img16.webp')`}}></div>
          </div>
        </div>
        <div className="screen workType">
          <div className="content">
            <div className="workType__wrap">
              <div className="workType__title title">Сайт</div>
              <div className="workType__desc text">
              Сайт жилого комплекса полностью поддерживает и развивает фирменный стиль, используя графические мотивы и цветовую палитру, отражающую архитектуру проекта. 
              <br/><br/>
              Информация подана ярко и структурировано, что&nbsp;позволяет пользователям легко погружаться в атмосферу комплекса. 
              <br/><br/>
              Удобный интерфейс обеспечивает простой и интуитивный доступ ко всем разделам, делая взаимодействие с сайтом комфортным для каждого посетителя.
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox gridBox_site" id="corporate-site">
            <div className="gridBox__item gridBox__item_1"><img src="/cases/img/dune/img17.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_2"><img src="/cases/img/dune/img18.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_3" style={{backgroundImage: `url('/cases/img/dune/img19.webp')`}}></div>
            <div className="gridBox__item gridBox__item_4" style={{backgroundImage: `url('/cases/img/dune/lines2.svg')`}}>
              <img src="/cases/img/dune/img20.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_5">
              <img src="/cases/img/dune/img21.webp" alt="" />
              <img src="/cases/img/dune/img22.webp" alt="" />
              <img src="/cases/img/dune/img23.webp" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_6" style={{backgroundImage: `url('/cases/img/dune/img24.webp')`}}></div>
            <div className="gridBox__item gridBox__item_7">
              <div className="gridBox__title">Территория<br/>комфорта</div>
              <Swiper 
                slidesPerView="auto" 
                className="comfortSwiper" 
                ref={this.swiperRef}
                modules={[Navigation]}
                navigation={true}
              >
                <SwiperSlide>
                  <img src="/cases/img/dune/img25.webp" alt="" />
                  <div className="comfortSwiper__text">Двор-парк<br/>без машин с доступом<br/>к Wi-Fi</div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/cases/img/dune/img26.webp" alt="" />
                  <div className="comfortSwiper__text">Консьерж-служба,<br/>круглосуточная охрана,<br/>видеонаблюдение</div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/cases/img/dune/img27.webp" alt="" />
                  <div className="comfortSwiper__text">Просторные лобби<br/>с колясочными<br/>и зонами ожидания</div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/cases/img/dune/img28.webp" alt="" />
                  <div className="comfortSwiper__text">Кафе, магазины<br/>и сервисы на первых<br/>этажах</div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/cases/img/dune/img29.webp" alt="" />
                  <div className="comfortSwiper__text">Детские и спортивные<br/>площадки</div>
                </SwiperSlide>
              </Swiper>
              
            </div>
            <div className="gridBox__item gridBox__item_8"><img src="/cases/img/dune/img30.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_9"><img src="/cases/img/dune/img31.webp" alt="" /></div>
            { /* <div className="gridBox__item gridBox__item_10">
              <div className="videoBlock">
                <div className="videoBlock__btn" style={{ backgroundImage: `url('/cases/img/dune/play.svg')` }}></div>
                <video id="video" poster="/cases/img/dune/video.jpg" loop preload="metadata">
                  <source src="/cases/img/dune/video.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div> */}
            <div className="gridBox__item gridBox__item_11">
              <div className="gridSmall">
                <div className="gridSmall__img"><img src="/cases/img/dune/img32.webp" alt="" /></div>
                <div className="gridSmall__text text">
                  Мобильная версия сайта жилого комплекса полностью повторяет функционал основной версии, обеспечивая пользователям доступ ко всем необходимым функциям в любое время и в любом месте. 
                  <br/><br/>
                  Интерфейс оптимизирован для удобства использования на мобильных устройствах, что делает навигацию простой и интуитивной. При этом визуальный дизайн сохраняет эстетическую привлекательность, отражая фирменный стиль проекта и создавая гармоничное восприятие даже на&nbsp;небольших экранах.
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_12">
              <img src="/cases/img/dune/img33.webp" alt="" />
              <img src="/cases/img/dune/img34.webp" alt="" />
              <img src="/cases/img/dune/img35.webp" alt="" />
              <img src="/cases/img/dune/img36.webp" alt="" />
            </div>
          </div>
        </div>
        <div className="screen workType">
          <div className="content">
            <div className="workType__wrap">
              <div className="workType__title title">Реклама</div>
              <div className="workType__desc text">
                Наружная реклама, разработанная на основе фирменного стиля жилого комплекса, эффективно привлекает внимание и передает ключевые характеристики проекта. 
                <br/><br/>
                Использование графических мотивов песчаных волн и цветовой палитры создает яркие и запоминающиеся образы. Такой подход позволяет рекламе гармонично вписываться в городскую среду и при этом выделяться на фоне других визуальных коммуникаций. 
                <br/><br/>
                Благодаря четкой подаче информации и узнаваемому стилю, наружная реклама усиливает имидж объекта и&nbsp;привлекает потенциальных покупателей.
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox gridBox_advert" id="corporate-advert">
            <div className="gridBox__item gridBox__item_1" style={{backgroundImage: `url('/cases/img/dune/img37.webp')`}}></div>
            <div className="gridBox__item gridBox__item_2"><img src="/cases/img/dune/img38.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_3">
              <img src="/cases/img/dune/img39.webp" alt="" />
              <img src="/cases/img/dune/img40.webp" alt="" />
            </div>
          </div>
        </div>
			</div>

      <CaseFooterNew 
				to={ this.props.next ? this.props.next.url: "/projects/neshalashi/"  }
				title= { this.props.next ? this.props.next.name: `Жилой комплекс “Дюна”`   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/dune/dune.webp" }
        full={true}
			/>
      <HelmetSite title='Жилой комплекс “Дюна”' />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Дюна</div>
				<div className="tags"><span>сайт</span><span>фирменный стиль</span><span>реклама</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dune)